:root {
  --header-height-dashboard: 6rem;
}

.sidebar-opened #main-screen-dashboard {
  transition: margin-left 0.5s;
  margin-left: 200px;
}

#main-screen-dashboard:hover #nav-bar {
  width: 200px;
  /* Set your desired expanded width for the sidebar */
}

#main-screen-dashboard:hover #header-toggle {
  transform: translateX(200px);
  /* Set the same width as the expanded sidebar */
}
.search_container {
  padding-inline: 80px;
}
.content-wrapper-ai {
  /* background: linear-gradient(to bottom left, #000024, #040459, #0059b3, #040459, #000024) !important; */
  background-color: rgb(248, 249, 250);
  min-height: 100vh;
  /* display: flex;
  align-items: center; */
  color: #000;
}

.txtGredientDashAI {
  -webkit-text-fill-color: #0000;
  background: -webkit-linear-gradient(100deg, #07db75 20%, #5746ec 80%);
  -webkit-background-clip: text;
  font-size: 50px;
  font-weight: 500;
}

.subHeadDashboard {
  color: #000;
  margin-top: 26px;
  margin-bottom: 30px;
  font-weight: 300;
  font-size: 22px;
  line-height: 36px;
}

.ai_textarea_input {
  color: "#5c5f62";
  font-weight: 500;
  margin: 0 0;
  font-size: 16px;
  padding: 15px 20px;
  border-radius: 4px;
  border-color: #707070;
}

.ai_sgstns {
  background-color: #fff;
  /* height: 120px; */
  padding: 16px 0;
  border-radius: 4px;
}

.searchWrapper {
  height: 20vh;
  display: flex;
  align-items: center;
  width: 100%;
}

.inputWrapperAI {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  /* border: 1px solid #d9d9d9; */
  border-radius: 4px;
  padding: 1px;
  background: -webkit-linear-gradient(100deg, #07db75 20%, #5746ec 80%);
  width: 100%;
}

.ai_sgstns .hd {
  color: #5746ec;
  font-size: 16px;
  font-weight: 300;
}

.ai_textarea_input ::placeholder {
  color: #707070;
}

.file_suffix {
  font-size: 12px;
  border: 1px solid #707070;
  padding: 8px 4px;
  border-radius: 4px;
}
@media screen and (min-width: 768px) {
  #main-screen-dashboard {
    padding-right: 1rem;
    padding-left: calc(var(--nav-width) + 1rem);
    transition: 0.5s;
  }
  #body-pd.active #main-screen-dashboard {
    padding-left: calc(var(--nav-width) + 150px) !important;
  }
}

.suggestion_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
}

.suggestion_pill {
  padding: 4px 8px;
  margin: 4px 10px 4px 0px;
  border: 1px solid #000;
  border-radius: 12px;
  font-size: 10px;
  cursor: pointer;
}

.dummy_boxes_container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
}
.dummy_box {
  display: block;
  width: 300px;
  height: 100px;
  padding: 4px 8px;
  margin: 8px 8px 8px 8px;
  border: 1px solid #707070;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
}

.custom_list .ant-list-bordered {
  border: none !important;
}

.parentWrapper {
  margin-top: 70px;
}
