.tp-list1 {
  margin-left: 0;
}

.tp-list1 li .las,
.next-list .las {
  font-size: 24px;
  color: #5746ec;
}

.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 2.55rem !important;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}

form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: none !important;
  font-size: 14px;
  border: 1px solid #dfdfdf;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

form .ant-input-lg,
.form-select {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: none !important;
  font-size: 14px;
  border: 1px solid #dfdfdf;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

form .ant-input-lg:hover,
form .ant-input-lg:active,
form .ant-input-lg:visited,
form .ant-input-lg:focus,
form .ant-select-selector:hover,
form .ant-select-selector:focus,
form .ant-select-selector:active,
.form-select:hover {
  border: 1px solid #5746eb !important;
}

.no_hvr .ant-input-lg:hover,
.no_hvr .ant-input-lg:active,
.no_hvr .ant-input-lg:visited,
.no_hvr .ant-input-lg:focus,
.no_hvr .ant-select-selector:hover,
.no_hvr .ant-select-selector:focus,
.no_hvr .ant-select-selector:active,
.no_hvr .form-select:hover {
  border: none !important;
}

form input[name="search-company"]:hover,
form input[name="search-company"]:focus,
form input[name="search-company"]:visited,
form input[name="search-company"]:active,
form input[name="search-company"] {
  border: none !important;
}

.addRightInput input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.addRightInput .ant-input-group-addon {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.dropdown-list {
  height: 300px !important;
  overflow-y: auto;
}

.react-clock {
  width: 115px !important;
  height: 115px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.react-clock__face {
  border: 1px solid rgb(82, 111, 215) !important;
}

.time-clock {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.06);
  display: inline-block;
  padding: 15px;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s;
  width: 100%;
}

/* .ant-card-head-title {
  color: #5746ec !important;
} */

.viewButton {
  background-color: #5746ec;
  color: #fff;
  font-weight: 500;
  padding: 3px 5px;
  border-radius: 5px !important;
}

.viewButton:hover {
  background-color: transparent;
  border-color: #5746ec !important;
  color: #5746ec !important;
}

.newsImg {
  object-fit: cover;
  height: 166px;
  width: 100%;
}

.two-line {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 35px;
}

.antdPagination {
}

.bg-white {
  background-color: #fff;
}

.borderLessHeader .ant-modal-body {
  max-height: 400px;
  overflow: auto;
}

.borderLessHeader .AiSummaryBody ul li {
  padding-left: 20px;
  position: relative;
  margin-bottom: 10px;
  font-size: 12px;
}

/* .borderLessHeader .AiSummaryBody ul li::before {
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 6px;
  background-color: #000;
} */

.cstmPagination {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
}

.cstmPagination li,
.cstmPagination li button,
.cstmPagination li a {
  border: none !important;
}

.cstmPagination li.ant-pagination-item-active {
  background-color: #5746ec !important;
  color: #fff !important;
}

.newsImgContainer {
  height: 100%;
  display: flex;
  align-items: stretch;
}

.newsImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.listType li {
  /* color: #05294b; */
  font-size: 12px;
  line-height: 20px;
}

.listType,
.listType li {
  list-style-type: disc !important;
}

.listType {
  padding-left: 16px !important;
}

.ant-segmented .ant-segmented-item {
  border: 1px solid transparent !important;
}

.ant-segmented .ant-segmented-item-selected {
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  color: rgb(76, 187, 23) !important;
  border: 1px solid rgb(76, 187, 23) !important;
}
