/* .search-app-container {
    max-height: 28rem;
} */

.common-filter-dropdown {
  font-size: 12px;
  color: #000;
  background-color: #fff;
  padding: 10px 12px;
  width: 170px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  border: none !important;
}

.common-filter-dropdown:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #000;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5746ec !important;
  text-shadow: 0 0 0.25px currentcolor;
  font-weight: 600 !important;
}

.ant-tabs-tab-btn {
  font-weight: 500 !important;
}

.common-filter-btn {
  font-size: 12px;
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.tras_smmry {
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 15px;
  margin-top: 2em;
}

.tras_smmry p {
  margin-bottom: 1em;
}

.tras_smmry .d-flex {
  /* margin-top: 1em; */
}

.tras_smmry small {
  margin-right: 1em;
}

.expert_dtls Button {
  /* background-color: #003137ff; */
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
}

.search-app-container .ant-spin-nested-loading,
.search-app-container .ant-card-body {
  overflow: auto;
  /* height: calc(100vh - 200px); */
  height: calc(100vh - 313px);
  /* max-width: 600px; */
  margin-bottom: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

/* Your CSS file or style block */
.clickable-list-item {
  cursor: pointer;
  transition: background-color 0.3s;
}

.clickable-list-item:hover {
  background-color: #f0f0f0;
  /* Adjust the background color on hover */
}

.clickable-list-item.clicked {
  background-color: #f0f0f0;
  /* Adjust the background color when clicked */
}

.search-app-container .ant-card-head-title {
  padding-top: 0px;
  padding-bottom: 0px;
}

.tagContainer {
  display:flex;
  align-items: center;
  justify-content: center;
  padding: 0.1em 0.1em;
  font-size: 12px;
  border: 1px solid #5746ec;
  background-color: white;
  color: #5746ec;
  cursor: pointer;
  width: 60px;
  text-align: center;
  /* margin-left: auto; */
  margin-right: 0;
}

.tag_icon {
  font-size: 11px;
}

.empty-state-container {
  padding: 0 50px 0 30px;
}

.findme-btn {
  /* padding: 7px 10px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px !important; */
}

.empty-state-content {
  text-align: left;
  max-width: 400px;
}

.empty-state-image img {
  max-width: 100%;
  height: auto;
}


.tagContainer.unlock:hover,
.tagContainer.view:hover {
  background-color: #ececff;
}

.highlight {
  /* background-color: yellow; */
  font-weight: bold;
}

.words{
  padding-left: 1px;
  padding-right: 1px;
}

.words.highlight{
  background-color: #5746ec;
  color: #fff;
  font-weight: normal !important;
}

.position-filter {
  top: 5px;
  right: 15px;
}

.page-height {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.05); */
  position: relative;
}

.blurred_portion {
  background: linear-gradient(rgba(255, 255, 255, 0.55), rgb(255, 255, 255));
  position: absolute;
  width: 100%;
  height: 116px;
  left: 0px;
  bottom: 0px;
  border-radius: 6px;
}

.blurred_text {
  position: absolute;
  top: 86px;
  left: 0;
  right: 0;
  z-index: 999;
  cursor: pointer;
  color: #1890ff;
  text-align: center;
}