.login-left-part h1{
    font-weight: 300;
    font-size: 20px;
}

.login-page{
    height: 100vh;
    overflow: hidden;
}

.authenticationSlider{

}

.authenticationSlider .swiper-pagination-bullet{
    background: #fff;
    opacity: 1;
}

.authenticationSlider .swiper-pagination-bullet-active{
    width: 30px;
    border-radius: 5px;
}