:root {
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #fff;
  --first-color-light: #dfdbef;
  --white-color: linear-gradient(to bottom left, #000024 10%, #040459, #0059b3 100%);
  --white-color-oldest: #f7f6fb;
  --body-font: "Nunito", sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100;
  --theme-black: #5c5f62;
  --hover: #5746ec;
  --black: #000;
  --theme-green: rgb(76, 187, 23);
}

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  /* position: relative;
    margin: var(--header-height) 0 0 0;
    padding: 0 1rem;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    transition: .5s */
  overflow: hidden;
}

.height100-145 {
  overflow-y: auto;
  max-height: calc(100vh - 90px);
  overflow-x: hidden;
  position: relative;
}

.cmmnHe {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.height100-196 .ant-list-items {
  max-height: calc(100vh - 280px);
  overflow-y: auto;
  overflow-x: hidden;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.05); */
  position: relative;
}

.heightTranscript .ant-list-items {
  max-height: calc(100vh - 330px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

body .ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer,
.ant-list-bordered.ant-list-sm .ant-list-item {
  /* padding: 8px !important; */
}

body .ant-list-split .ant-list-item {
  border-bottom-color: #f8f8f8 !important;
}

/* #backDropCls.active{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
 } */

.cstmTg .ant-tag,
.ant-tag a,
.ant-tag a:hover {
  border-color: #f8f8f8 !important;
}

.fs-12 {
  font-size: 12px;
}

.fw-semi-bolder {
  font-weight: 600;
}

a {
  text-decoration: none;
}

.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background: var(--white-color);
  z-index: var(--z-fixed);
  transition: 0.5s;
}

.header_toggle {
  color: var(--theme-black);
  font-size: 1.5rem;
  cursor: pointer;
}

.header_img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.header_img img {
  width: 40px;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--black);
  padding: 0.5rem 1rem 0 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
}

.header.active,
.l-navbar.active {
  z-index: 1024 !important;
}

.sidebar_nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav_link_avatar {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1rem;
  margin-bottom: 0;
}

.nav_logo {
  margin-bottom: 2rem;
}

.nav_logo-icon {
  font-size: 1.25rem;
  color: var(--white-color);
}

.nav_logo-name {
  color: var(--white-color);
  font-weight: 700;
}

.nav_link {
  position: relative;
  color: var(--first-color-light);
  margin-bottom: 1rem;
  transition: 0.5s;
}

.nav_link:hover {
  color: var(--white-color);
}

.nav_icon {
  font-size: 1.25rem;
}

.nav_name,
.popover-content {
  font-size: 14px;
}

.popover-content .ant-space-item {
  color: var(--theme-black);
}

.popover-link:hover .ant-space-item {
  color: var(--hover);
}

.popover-userInfo {
}

.popover-userInfo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.popover-userInfo h5 {
  font-size: 16px;
}

.popover-userInfo small {
  font-size: 12px;
  color: darkgrey;
}

.credits-info {
  /* border: 1px solid var(--hover); */
  /* color: var(--hover); */
  border: 1px solid var(--first-color);
  color: var(--first-color);
  padding: 7px 10px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
}

.credits-info-filled {
  border: 1px solid var(--hover);
  background-color: var(--hover);
  color: var(--white-color);
  padding: 7px 10px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
}

.cmmn-search-dropdown {
  font-size: 12px;
  color: var(--white-color);
  background-color: var(--theme-black);
  padding: 10px 12px;
  width: 170px;
  border-radius: 5px;
}

.cmmn-search-dropdown:hover {
  color: var(--white-color);
}

.cstm-wishlist {
  color: var(--theme-black);
  font-size: 12px;
}

.search-input {
  border-color: var(--hover) !important;
  font-size: 12px !important;
  color: var(--theme-black) !important;
  border-radius: 5px !important;
  height: 40px;
}

.show {
  left: 0;
}

.toggle_icon {
  min-width: initial;
  background-color: #fff;
  position: absolute;
  right: -12px;
  top: 50px;
  z-index: 1;
  -webkit-filter: drop-shadow(rgba(21, 35, 66, 0.05) 0px 0px 4px);
  filter: drop-shadow(rgba(21, 35, 66, 0.05) 0px 0px 4px);
  border-radius: 50%;
  height: 24px;
  padding: 0;
}

.toggle_icon .left_icon {
  align-items: center;
  display: inline-flex;
  font-feature-settings: "liga";
  font-size: 24px;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-indent: 0px;
  vertical-align: middle;
  user-select: none;
  transform: rotate(-180deg);
  transition: all 0.5s ease-in-out;
  color: rgb(48, 52, 54);
  caret-color: rgb(48, 52, 54);
  font-weight: 400;
}

body.active .toggle_icon .left_icon {
  transform: rotate(0deg);
}

#nav-bar .active {
  color: var(--theme-green);
}

#nav-bar .active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0px;
  height: 32px;
  background-color: var(--white-color);
}

.height-100 {
  height: 100vh;
}

.height-80 {
  height: 80vh;
}

.company-name .ant-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.1em 0.1em;
  font-size: 12px;
  background-color: white;
  cursor: pointer;
  min-width: 60px;
  text-align: center;
  margin-right: 0;
}

.css-1myzk3s {
  border: 1px solid #5746ec !important;
}

.tag-public {
  border: 1px solid #5746ec !important;
  color: #5746ec !important;
}

.tag-private {
  border-color: rgb(7, 219, 117) !important;
  color: rgb(7, 219, 117) !important;
}

.manage_logo {
  width: var(--nav-width);
  overflow: hidden;
  position: relative;
  /* left: -6px; */
  top: 0px;
  transition: 0.5s;
}

.manage_logo img {
  width: 155px;
  max-width: 155px;
}

.submenu_link {
  position: relative;
  color: var(--first-color-light);
  margin-bottom: 0rem !important;
  transition: 0.5s;
}

body.active .l-navbar .manage_logo {
  width: 155px;
}

.l-navbar.show .manage_logo img {
}

/* Add this CSS to your stylesheet */

/* Add this CSS to your stylesheet */

/* Add this to your stylesheet */

.sidebar-opened #main-screen {
  transition: margin-left 0.5s;
  margin-left: 200px;
}

/* Adjust the styles for other elements if needed */

#main-screen:hover #nav-bar {
  width: 200px;
  /* Set your desired expanded width for the sidebar */
}

form .ant-form-vertical .ant-form-item-label,
form .ant-col-24.ant-form-item-label,
form .ant-col-xl-24.ant-form-item-label {
  padding: 0 0 0;
}

form .ant-form-item {
  margin-bottom: 15px;
}

#main-screen:hover #header-toggle {
  transform: translateX(200px);
  /* Set the same width as the expanded sidebar */
}

.company-logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
}

.company-name {
  vertical-align: middle;
  margin-right: 8px;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.ant-dropdown-menu-item {
  padding: 8px 16px;
}

.ant-dropdown-menu-submenu-title {
  padding: 8px 16px;
}

@media screen and (min-width: 768px) {
  body {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    /* padding-left: calc(var(--nav-width) + 0rem) */
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 0.7rem 0 calc(var(--nav-width) + 1rem);
  }

  #main-screen-old {
    padding-top: calc(var(--header-height) + 2rem);
    padding-right: 1rem;
    padding-left: calc(var(--nav-width) + 1rem);
    transition: 0.5s;
  }
  #main-screen {
    padding-top: calc(var(--header-height) + 1rem);
    padding-right: 0rem;
    padding-left: calc(var(--nav-width) + 0.001rem);
    /* padding: 0 0.7rem 0 calc(var(--nav-width) + 1rem); */
    transition: 0.5s;
  }

  .header_img {
    width: 40px;
    height: 40px;
  }

  .header_img img {
    width: 45px;
  }

  .l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0;
  }

  body.active .l-navbar {
    width: calc(var(--nav-width) + 132px);
  }

  #body-pd.active .header {
    padding-left: calc(var(--nav-width) + 150px) !important;
  }

  #body-pd.active #main-screen {
    padding-left: calc(var(--nav-width) + 150px) !important;
  }
}

.ant-popover-placement-bottomRight .ant-popover-inner-content {
  background-color: transparent !important;
}

.dashboardSearchBar {
  position: absolute;
  top: 82px;
  margin: 0;
  z-index: 9;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 0.4rem 0 calc(var(--nav-width) + 1rem);
  transition: 0.5s;
}

#body-pd.active .dashboardSearchBar {
  padding-left: calc(var(--nav-width) + 150px) !important;
}

.ai-message li {
  margin-bottom: 5px;
}

.history_list {
  position: relative;
  overflow-x: scroll;
  height: 230px;
  left: 26px;
  overflow-y: scroll;
  line-height: normal;
  scrollbar-width: none; /* Hides scrollbar */
}

.question_li {
  list-style: unset;
  list-style-position: inside;
}
.history_list .load_more {
  margin-left: 16px;
  list-style: none;
  cursor: pointer;
  text-decoration: underline;
  color: #fff;
}

.history_list .load_more:hover {
  color: var(--theme-green);
}

.history_list::-webkit-scrollbar {
  display: none; /* Hides the scrollbar */
}

.history_question {
  color: #fff;
  padding: 4px 4px;
}
