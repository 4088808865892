/* App.css */
/* body{
  overflow: hidden;
} */

.app-container {
}

.main-card {
  width: 100%;
}

.trans-disc .ant-card-body {
  padding: 0px 24px !important;
}

.modified_trans {
  color: #000;
  font-size: 12px;
  line-height: 20px;
  padding-right: 1.5rem;
}

.team-info [title] {
  pointer-events: none;
}

.summary-container {
  overflow-y: auto;
  height: calc(100vh - 275px);
  /* max-width: 600px; */
  margin-bottom: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
.summary-container-comp {
  overflow-y: auto;
  height: calc(100vh - 240px);
  margin-bottom: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.bckIcon_trans {
  position: absolute;
  left: -5px;
}

.chat-container {
  /* overflow: auto;
  height: calc(100vh - 400px); */
  /* max-width: 600px; */
  /* margin-bottom: 0;
  scroll-behavior: smooth;
  overflow-x: hidden; */
}

.message-container {
  border-top: 1px solid #e3e8ef !important;
}

.music-player {
  /* max-width: 600px; */
  margin-bottom: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.music-span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.music-not-found {
  background-color: #cac4c4;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-container1 {
  overflow: auto;
  height: calc(100vh - 272px);
  max-width: 700px;
  margin-bottom: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.transcriptDetails .ant-card-body {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.fsfsed .ant-card-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fsfsed .recording_summary {
  height: calc(100vh - 95px);
}

.fsfsed .smry-para .ant-collapse {
  background-color: #fff !important;
}

.transcriptDetails .ant-card-head {
  border-bottom: none;
  min-height: 49px !important;
  padding-left: 10px;
}

/* .summary-container::-webkit-scrollbar,
.chat-container::-webkit-scrollbar {
  width: 2px;
}

.summary-container::-webkit-scrollbar-thumb,
.chat-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.summary-container::-webkit-scrollbar-track,
.chat-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.summary-container::-webkit-scrollbar-thumb:hover,
.chat-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
} */

.trans-header {
  font-size: 14px !important;
}

.fw-normal {
  font-size: 12px !important;
}

.chat-messages {
  height: 90%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-message {
  /* margin-bottom: 10px; */
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
}

.ai-message {
  background-color: #e6f7ff85;
  padding: 8px;
  border-radius: 8px;
  margin-right: auto;
  width: 74%;
  font-size: 12px;
  /* Push the user message to the left */
}

.user-message {
  background-color: #f0f5ff;
  padding: 8px;
  border-radius: 8px;
  margin-left: auto;
  /* word-break: break-all; */
  max-width: 70%;
  /* Push the AI message to the right */
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 0px;
  position: sticky;
  bottom: 50px;
  background-color: white;
}

.message-input {
  border-radius: 8px;
}

.send-button {
  margin-left: 10px;
}

.message-input {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding-right: 10px;
  height: 40px;
  padding: 8px 12px;
  background-color: transparent !important;
  border: 1px solid #2545d3 !important;
}

.send-button {
  background-color: #5746ec !important;
  transition: background-color 0.3s ease;
  color: #fff !important;
  border: 0;
  border-radius: 8px;
  height: 40px;
  padding: 8px 12px;
}

.bot-avatar {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  border-radius: 100%;
}

/* CHAT BUBBLE */
.chat-bubble {
  background-color: #f0f5ff;
  padding: 16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}

.typing {
  align-items: center;
  display: flex;
  /* height: 17px; */
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #407aee;
  /* //rgba(20,105,69,.7); */
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

.full-control {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

/* .chat-container {
  padding: 16px;
} */

.chat-empty-card {
  border: 1px solid #dfe0f8;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  transition: transform 0.2s ease-in-out, border 0.2s ease-in-out;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
}

/* .chat-card .ant-card-head {
  padding: 0 15px !important;
}

.chat-card .ant-card-body {
  padding: 0 15px !important;

} */

.chat-empty-card:hover {
  transform: scale(1);
  border: 1px solid #2545d3 !important;
}

.chat-container {
  height: calc(100vh - 240px) !important;
}

.title-container {
  display: flex;
  /* justify-content: space-between; */
}

/* .left-side,
.right-side {
  width: 50%;
} */

.title,
.title-data {
  font-size: 13px;
}

.left-side {
  padding-right: 10px;
  border-right: 1px solid #ccc;
}

.right-side {
  padding-left: 10px;
}

.left-side,
.right-side {
  padding-top: 4px;
  padding-bottom: 5px;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #407aee;
    /* // rgba(20,105,69,.7); */
  }

  28% {
    transform: translateY(-7px);
    background-color: #6c80a6;
    /* //rgba(20,105,69,.4); */
  }

  44% {
    transform: translateY(0px);
    background-color: #697692;
    /* //rgba(20,105,69,.2); */
  }
}

.MuiSlider-root {
  padding: 5px 0 !important;
}

.fxdLeft {
  flex: 0 0 auto;
  width: 115px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.fxdLeft::after {
  content: "";
  height: 30px;
  width: 1px;
  position: absolute;
  right: 14px;
  top: 0;
  background-color: #f0f0f0;
}

.fxdRght {
  flex: 0 0 auto;
  width: 167px;
}

.fxdCmpnyTrans {
  flex: 0 0 auto;
  width: 300px;
}

.div_50 {
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.middleTitle::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.middleTitle {
  -ms-overflow-style: none;
  width: 80%;
  overflow-x: auto;
  /* IE and Edge */
  scrollbar-width: none;
  position: relative;
  max-height: 30px;
  /* Firefox */
}

.jf_ds {
  /* height: 30px; */
  position: relative;
  margin-bottom: 8px;
}

.modified_trans strong {
  font-weight: 600;
}

.searchPageTranscript,
.transcriptDetails,
.searchPageTranscript .sd_Sde_ {
  max-height: calc(100vh - 190px);
  overflow-y: auto;
  overflow-x: hidden;
}

.searchPageTranscriptExpert .ant-card-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}

.sd_Sde_ .ant-card-head {
  border-bottom: none;
}

.transcript_wrap .trans_popup {
  height: 135px;
}
.transcript_wrap .trans_popup_comp {
  height: 100px;
}
