.follow_up_container {
  padding: 5px;
  font-size: 12px;
  border: 1px solid #5746ec;
  width: 100%;
  border-radius: 4px;
  margin-top: 6px;
  /* margin-top: 30px; */
}

.follow_up_container .ant-input {
  border: none;
}
.follow_up_container .ant-input-outlined {
  border: none;
}
.content-body {
  background: #f7f6fb !important;
  /* background: linear-gradient(to bottom left, #000024, #040459, #0059b3, #040459, #000024) !important; */
  overflow-y: auto;
  overflow-x: hidden;

  /* border-bottom: 1px solid rgba(0, 0, 0, 0.05); */
  /* position: relative; */
}

.answer-card-body .ant-card-head {
  padding: 0 15px !important;
  border-bottom: none;
}

.question_body {
  align-items: center;
  float: right;
  font-size: 16px;
  /* background-color: #d9d9d9dd; */
  width: 100%;
  max-width: 80%;
  padding: 12px;
  border-radius: 8px;
  margin: 4px 4px 0px 0px;
  background-color: rgba(0, 0, 0, 0.02);
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  border: 1px solid #5746ec; /* Light border */
}

.answer_body {
  align-items: center;
  float: left;
  border-radius: 4px;
  margin: 4px 4px 4px 0px;
  width: 100%;
  max-width: 90%;
}

.qna_body {
  height: 100vh;
  flex: 1;
  max-height: calc(100vh - 104px);
  /* max-height: calc(100vh - 120px); */
  overflow-y: scroll;
  overflow-x: hidden;
}

.qna_body_sec {
  max-height: calc(100vh - 142px);
  height: 100vh;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}

.qna_body p {
  /* margin-bottom: 15px; */
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

.qna_body li {
  margin-bottom: 5px;
}

.qna_body ol {
  padding-left: 30px;
  list-style: auto;
}

.qna_body ol li {
  margin-bottom: 5px;
}

.answer_body_sec {
  height: 100vh;
  flex: 1;
  max-height: calc(100vh - 14px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.answer_body_sec_loading {
  height: 100vh;
  flex: 1;
  max-height: calc(100vh - 14px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.answer_body_wrapper {
  border-radius: 10px;
}
.answer_body_wrapper_left {
  /* border: 1px solid #707070; */
  padding-right: 10px;
  border-radius: 10px;
  font-size: medium;
}

.answer_body_sec .ant-card {
  /* box-shadow: 0px 3px 6px #00000029; */
  border: 1px solid rgba(112, 112, 112, 0.2);
  border-radius: 10px;
}

.answer_body_sec .ant-card-head {
  min-height: 36px;
}

.answer_table .ctm_header,
.answer_table .ctm_body {
  padding: 0 15px;
  margin-bottom: 5px;
}

/* .answer_table .ctm_body {
  height: 100px;
  overflow-y: scroll;
} */

.answer_table .ctm_header div {
  font-weight: 500;
}

.answer_table .ctm_body div {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2px;
}

.answer_body_sec .ant-card-body {
  padding: 0 0 5px 0;
}

.button-gradient {
  background: linear-gradient(180deg, #b648c9, #9712c3);
  color: white;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;
  margin-bottom: 10px;
  height: 44px;
  border-radius: 15px;
  font-size: 16px;
  padding: 0 0;
}

.result_button {
  background-color: #d9d9d9;
  font-size: 12px;
  font-weight: 500;
  height: 26px;
}

.result_button :hover {
  background-color: transparent !important;
  border-color: #5746ec !important;
  color: #5746ec !important;
}
.result_override .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: transparent !important;
  border-color: #5746ec !important;
  color: #5746ec !important;
}
.result_button :focus {
  background-color: transparent !important;
  border-color: #5746ec !important;
  color: #5746ec !important;
}

.custom-table .ant-table-thead > tr > th {
  padding: 0px !important;
}
.avatar_container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.answer-card-body .ant-card {
  /* border: 1px solid #707070; */
  border-radius: 4px;
}

.step_loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050; /* Ensure it's above other elements */
  /* background-color: white; Optional: to give a solid background to the steps */
  padding: 20px;
  color: #9712c3;
  /* border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); */
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 1040; /* Below the loader but above the page */
}

.custom_step_style .ant-steps-item .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
  color: white;
}

.source_content {
  width: 350px;
  font-size: small;
}

.custom_card {
  background-color: #fff; /* White background */
  border-radius: 4px; /* Rounded corners */
  /* padding: 16px; Inner spacing */
  margin: 10px 0;
}
.custom_card_right {
  background-color: #fff; /* White background */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  /* padding: 16px; Inner spacing */
  margin: 0px 0px 8px 0px;
  border: 1px solid #e0e0e0; /* Light border */
  /* max-width: 400px; Optional: set a max width */
}

.list_style_ans_accordian div li {
  list-style: disc;
}
.list_style_ans_accordian div ol li {
  list-style: decimal;
}

.reset_anchor {
  text-decoration: underline;
  color: #5746ec;
}

.themeBlue {
  color: #5746ec;
}

.themeWhite {
  color: #fff;
}

.themeButtons {
  border-color: rgb(76, 187, 23);
  color: rgb(76, 187, 23);
  cursor: pointer;
  font-size: 12px;
  background-color: transparent;
}

.answer_table .ant-collapse-header {
  border-bottom: 1px solid #f0f0f0;
}

.pointer {
  cursor: pointer;
}

.chat-bubble {
  background-color: rgb(87, 81, 223, 0.2);
  padding: 16px 28px;
  margin-block: 2px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}

.typing {
  align-items: center;
  display: flex;
  /* height: 17px; */
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #5746ec;
  /* //rgba(20,105,69,.7); */
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}
