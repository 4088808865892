.min-h-150 {
  min-height: 150px;
}

.ck-focused {
  min-height: 110px;
}

.react_datepicker {
  height: 40px;
  width: 100%;
}
