.control_Container {
  /* background-color: rgba(0, 0, 0, 0.6); */
  /* position: absolute; */
  /* top: 0;
  bottom: 0;
  right: 0;
  left: 0; */
  flex-direction: column;
  /* z-index: 1; */
  display: flex;
  justify-content: space-between;
}
.timing {
  color: black;
}

.top_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 20px;
}

.mid__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon__btn {
  padding: 0 10px;
  color: #5746ec;
}
.icon__btn svg {
  height: 25px !important;
  width: 25px !important;
}

.slider__container {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 10px; */
}

.control__box {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.flex__slider {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner__controls {
  display: flex;
  /* padding: 10px 0; */
  align-items: center;
  justify-content: center;
  /* width: 50%; */
}

.slider__color {
  color: #5746ec;
  font-size: 0.8rem;
  display: flex;
  /* margin-left: 10px; */
}

.second__control {
  display: flex;
  align-items: center;
}

.bottom__container {
  padding: 10px 10px 10px 0px;
}
