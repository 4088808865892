.mobile-text {
  color: rgb(132 147 166);
  font-size: 14px;
}

.opt-inputs {
  margin-right: 12px;
  text-align: center;
}

.opt-inputs:focus {
  color: #495057;
  background-color: #fff;
  border-color: #5746ec;
  outline: 0;
  box-shadow: none;
}

.cursor {
  cursor: pointer;
}

.payment-button .verify-btn {
  height: 40px;
  font-size: 15px;
  background-color: #000 !important;
  color: #fff !important;
  text-decoration: none;
  text-transform: capitalize !important;
}
