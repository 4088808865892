.tp-list1 {
  margin-left: 0;
}

.tp-list1 li .las,
.next-list .las {
  font-size: 24px;
  color: #5746ec;
}

.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 2.55rem !important;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 7%);
  font-size: 14px;
  border: 1px solid #dfdfdf;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.dropdown-list {
  height: 300px !important;
  overflow-y: auto;
}

.react-clock {
  width: 115px !important;
  height: 115px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.react-clock__face {
  border: 1px solid rgb(82, 111, 215) !important;
}

.time-clock {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.06);
  display: inline-block;
  padding: 15px;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s;
  width: 100%;
}

/* .ant-card-head-title {
  color: #5746ec !important;
} */

.viewButton {
  font-weight: 500;
  background-color: transparent;
  border-color: #5746ec !important;
  color: #5746ec !important;
  white-space: pre !important;
}

.viewButton:hover {
  background-color: #5746ec !important;
  border-color: #5746ec !important;
  color: #fff !important;
}

.newsImg {
  object-fit: cover;
  height: 166px;
  width: 100%;
}

.two-line {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 35px;
}

.antdPagination {
}

.bg-white {
  background-color: #fff;
}

.borderLessHeader .ant-modal-body {
  max-height: 400px;
  overflow: auto;
}

.borderLessHeader .AiSummaryBody ul li {
  padding-left: 20px;
  position: relative;
  margin-bottom: 10px;
}

.borderLessHeader .AiSummaryBody ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 7px;
  background-color: #000;
}

.cstmPagination {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
}

.cstmPagination li,
.cstmPagination li button,
.cstmPagination li a {
  border: none !important;
}

.cstmPagination li.ant-pagination-item-active {
  background-color: #5746ec !important;
  color: #fff !important;
}

.newsImgContainer {
  height: 100%;
  display: flex;
  align-items: stretch;
}

.newsImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.listType li {
  /* color: #05294b; */
  font-size: 12px;
  line-height: 20px;
}

.listType,
.listType li {
  list-style-type: disc !important;
}

.listType {
  padding-left: 16px !important;
}

.ant-badge-count {
  border-radius: 4px !important;
}

/* .summary-container::-webkit-scrollbar,
.chat-container::-webkit-scrollbar {
  width: 4px;
}

.summary-container::-webkit-scrollbar-thumb,
.chat-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.summary-container::-webkit-scrollbar-track,
.chat-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.summary-container::-webkit-scrollbar-thumb:hover,
.chat-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
} */

.ant-list {
  background: rgb(255, 255, 255);
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important; */
  transition: box-shadow 0.3s ease !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 2px !important;
}

.ant-card-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.ant-tabs-tab:hover {
  color: #5746ec !important;
}

.cust_header {
  color: #5746ec !important;
  font-weight: 500 !important;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #5746ec !important;
  pointer-events: none;
}

/* .ant-btn:hover,
.ant-btn:focus {
  color: #5746ec !important;
  border-color: #5746ec !important;
  background: #fff;
} */

/* .dashboard_page .ant-list-items {
  overflow: auto;
  height: calc(100vh - 440px);
  margin-bottom: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
} */

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.trans_popup .ant-divider-horizontal,
.expert_details .ant-divider-horizontal {
  margin: 8px 0;
}

.segmented-tooltip [title] {
  pointer-events: none !important;
}

.fw-bolder {
  font-weight: 500 !important;
}

.ant-popover-inner {
  max-height: calc(100vh - 100px);
  max-width: 40vw;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f4f4f4 !important;
  /* padding-right: 6px; */
}

.ant-popover-arrow::before {
  background: #f4f4f4 !important;
}

.unlockTrnscrptBttn {
  position: absolute;
  background-color: #5746ec;
  color: #fff !important;
  left: 0;
  right: 0;
  max-width: 160px;
  margin: auto;
  bottom: 0;
  font-size: 12px;
}

.unlockTrnscrptBttn:hover {
  background-color: #5746ec;
  color: #fff !important;
}

.ant-popover-arrow .ant-popover-arrow-content {
  --antd-arrow-background-color: #f4f4f4;
}

.top_experts .ant-spin-container {
  max-height: calc(100vh - 195px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 6px;
}

.expertExp {
  max-height: calc(100vh - 224px);
  overflow-y: auto;
  overflow-x: hidden;
}
.expertExpSidebar {
  max-height: calc(100vh - 266px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 0px;
}

.txtGredient {
  background: -webkit-linear-gradient(120deg, #07db75 50%, #5746ec 110%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.expert_smry ul li {
  line-height: 18px;
  font-size: 12px;
}

.height_dashboard {
  max-height: calc(100vh - 150px);
  margin-top: 60px;
}

.top_experts,
.abtExprt {
  margin-top: 60px;
}

.projectTable .ant-spin-container {
  max-height: calc(100vh - 230px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.fs-16-input input {
  font-size: 14px !important;
}
